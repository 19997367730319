<template>
 <!-- 该页面未使用 -->
 <div class="dialogContent">
  <el-dialog :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="6.71rem">
   <div :key="index" class="row" v-for="(item,index) in editComponents">
    <component :code="comp.code" :codeField="comp.codeField" :is="comp.component" :key="cindex" :labelName="comp.labelName" @changeCode="getComponentCode" v-for="(comp,cindex) in item.CompArr"></component>
   </div>
   <p class="row rowbutton">
    <el-button :class="item.className" :key="item.compKey" :type="item.type" @click="item.clickEvent" v-for="item in tableLowBtns">
     <i :class="item.iconClass"></i>
     {{item.labelName}}
    </el-button>
   </p>
  </el-dialog>
 </div>
</template>

<script>
import { oneEditTemplateMixins } from '@/views/mixins'

export default {
 data() {
  return {
   tit: '',
   dialogVisible: false,
   currObj: {},
  }
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
  },
  cancelDialog() {
   this.dialogVisible = false
  },
  showDialog(title = '新增出错记录', data = {}) {
   this.tit = title
   this.dialogVisible = true
   this.currObj = data
  },
 },
}
</script>

<style lang="less" scoped>
.dialogContent {
 border-radius: 5px;
 box-sizing: border-box;

 .row {
  display: flex;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0.1rem;
 }

 .column {
  position: relative;
  width: 2.8rem;
  margin-right: 0.3rem;

  input {
   width: 2.8rem;
   // font-size: 1.23em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   height: 0.36rem;
   text-indent: 8px;
  }

  input::-webkit-input-placeholder {
   color: #b1b1b1;
  }

  .yogo-input-name {
   color: #000000;
   font-size: 2em;
   padding-bottom: 0.2rem;
  }

  .btnicon {
   position: absolute;
   right: 0px;
   height: 0.36rem;
   width: 0.36rem;
   background: #d2d2d2 url(../../statics/images/icondown2.png) center center no-repeat;
   border-radius: 0 5px 5px 0;
  }

  .btnimgdown {
   background-image: url(../../statics/images/icondown2.png);
   background-size: 0.12rem 0.08rem;
  }

  .btnimgdate {
   background-image: url(../../statics/images/icondate2.png);
   background-size: 0.16rem 0.15rem;
  }
 }

 .columndesc {
  textarea {
   // font-size: 1.23em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   width: 5.91rem;
   height: 1.2rem;
   text-indent: 8px;
  }
 }
}
</style>
